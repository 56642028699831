import React, {  } from 'react';
import { GridView } from '@components/icon/icon'
import { Link } from 'gatsby';

function GetgridLink(props) {
    const OpenResult= () => {
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
        const mapUrl = currentUrl.replace("property-map", "property")
        if (typeof window !== "undefined") {
            window.location = mapUrl
        }
    }
    return (
        <a className="text-20-16 utils-nav-item utils-nav-item-map" href="javascript:;" onClick={(e)=>OpenResult()}><GridView /><span className="text-20-16">Grid View</span></a>
    )
}
export default GetgridLink